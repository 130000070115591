import axios from 'axios';
var UsersService = /** @class */ (function () {
    function UsersService() {
    }
    UsersService.prototype.list = function () {
        return axios.get('/api/ivy-auth/v1/users/', {
            withCredentials: true
        });
    };
    UsersService.prototype.create = function (user) {
        return axios.post('/api/ivy-auth/v1/users/', user, {
            withCredentials: true
        });
    };
    UsersService.prototype.get = function (userId) {
        return axios.get('/api/ivy-auth/v1/users/' + userId, {
            withCredentials: true
        });
    };
    UsersService.prototype.update = function (userId, user) {
        return axios.patch('/api/ivy-auth/v1/users/' + userId, user, {
            withCredentials: true
        });
    };
    UsersService.prototype.delete = function (userId) {
        return axios.delete('/api/ivy-auth/v1/users/' + userId, {
            withCredentials: true
        });
    };
    UsersService.prototype.getUserRoles = function (userId) {
        return axios.get('/api/ivy-auth/v1/users/' + userId + '/roles', {
            withCredentials: true
        });
    };
    UsersService.prototype.getUserGroups = function (userId) {
        return axios.get('/api/ivy-auth/v1/users/' + userId + '/groups', {
            withCredentials: true
        });
    };
    UsersService.prototype.addUserOrganizationDomainRole = function (userId, organization, domain, role) {
        return axios.post('/api/ivy-auth/v1/manage/users/' + userId + '/organizations/' + organization + '/domains/' + domain + '/role/' + role, {}, {
            withCredentials: true
        });
    };
    UsersService.prototype.removeUserOrganizationDomainRole = function (userId, organization, domain, role) {
        return axios.delete('/api/ivy-auth/v1/manage/users/' + userId + '/organizations/' + organization + '/domains/' + domain + '/role/' + role, {
            withCredentials: true
        });
    };
    return UsersService;
}());
export default new UsersService();
